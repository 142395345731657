import React from "react"


import Header from "./header"
import Footer from "./footer"
import "@fontsource/merriweather" //https://fontsource.github.io/search-directory/#
import "./layout.css"

const Layout = ({ location, title, children }) => {
  return (
    <div class="d-flex flex-column min-vh-100">
      <Header title={title} />
      <main role="main" class="container-md shadow">
        {children}
      </main>
      <Footer title={title} />
    </div>
  )
}

export default Layout
