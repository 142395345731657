import React from "react"
import SocialFollow from "./socialfollow"

const Footer = ({ title }) => {
  return (
    <footer>
      <div class="container">
        <SocialFollow />
        <small class="mb-3 d-flex justify-content-center">
          <a class="px-1" href="/about/">About</a>|
          <a class="px-1" href="/privacy/">Privacy Policy</a>|
          <a class="px-1" href="#top">Top</a>
        </small>
        <small class="mb-3 d-flex justify-content-center">
          © 2020 - {new Date().getFullYear()} Hike Log. All rights reserved.
        </small>
      </div>
    </footer>
  )
}

export default Footer
