//https://www.digitalocean.com/community/tutorials/creating-a-social-follow-component-in-react

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"

import "./socialfollow.css"

const SocialFollow = ({ title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              instagram
              facebook
              youtubeChannel
            }
          }
        }
      }
    `
  )
  const twitterURL = "https://twitter.com/" + site.siteMetadata.social.twitter

  const instagramURL =
    "https://www.instagram.com/" + site.siteMetadata.social.instagram

  const facebookURL =
    "https://www.facebook.com/" + site.siteMetadata.social.facebook

  const youtubeURL =
    "https://youtube.com/channel/" + site.siteMetadata.social.youtubeChannel

  return (
    <div class="social-container">
      <a href={youtubeURL} className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href={facebookURL} className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href={twitterURL} className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a href={instagramURL} className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
    </div>
  )
}

export default SocialFollow
